<script>
import GlobalVue from '../../helper/Global.vue';
import Gen from '@helper/Gen';
export default {
	extends: GlobalVue,
	props: {
		type: String,
	},
	data() {
		return {
			data: []
		}
	},
	async mounted() {
	},
	methods: {

	}
}
</script>

<template>
<section id='loader'>
    <section id='boatWave'>
        <img :src="assets('fo_images', 'loader/loader2.png')" id='boat'>
        <img :src="assets('fo_images','loader/loader-wave.png')" id='wave'>
    </section>
</section>
</template>